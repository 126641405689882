* {
  box-sizing: border-box;
}

.location:focus {
  border-width: 2px !important;
  /* border: 4px !important; */
  border-color: #2065d1 !important;
  outline: none;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.header {
  padding-top: 10px;
  background-color: #fff;
}

.logo_imgs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 10px rgb(217 217 217 / 10%);
}

.copyright p {
  margin: 7px 0 0;
  font-size: 14px;
}

footer {
  background: #ededed;
  padding: 30px 0 0;
}

.sign_in {
  max-width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 60px 15px;
}

.sign_in div {
  min-height: auto;
  padding: 0;
  text-align: center;
}

.sign_in img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.sign_in>div {
  background: #fff;
  box-shadow: 0 0 25px rgba(53, 53, 53, .1);
}

.right_side h3 {
  text-align: center;
  margin: 50px 0;
  color: #00793a;
}

.sign_in button {
  background: #d7b56d !important;
}

footer img {
  margin-left: auto;
}

.right_side a {
  color: #e31e24;
  font-weight: 600;
}

.contact_info li {
  list-style: none;
  display: flex;
  font-size: 16px;
  padding-bottom: 20px;
}

.contact_info a {
  color: #000;
  text-decoration: none;
  word-break: break-all;
}

.contact_info li svg {
  font-size: 18px;
  color: #d7b56d;
  margin-right: 10px;
  margin-top: 3px;
}

.copyright {
  background: #dcdcdc;
  margin-top: 40px;
  padding-bottom: 15px;
}

footer h4 {
  margin-bottom: 20px !important;
  font-size: 22px !important;
}

.contact_info li p {
  margin: 0;
}

.sign_in h4 {
  font-size: 26px;
}

footer .about {
  padding-right: 40px;
}

footer .about a {
  color: #00793a;
  font-weight: 600;
  text-decoration: none;
}

footer .about a:hover {
  text-decoration: underline;
}

footer iframe {
  border: 0;
  width: 100%;
  height: 250px;
}

.social_icons {
  list-style: none;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.social_icons svg {
  font-size: 30px;
  margin: 15px 15px 0 0;
}

footer .about h6 {
  margin-top: 20px;
}

.right_side p {
  font-size: 16px;
  color: #212B36;
}

.sidebar_menus {
  background: #fafafa;
}

.user_info>a>div {
  background-color: #00793a;
}

.user_info h6,
.user_info p {
  color: #fff;
}

.dash_logo {
  background: #ecfaf3;
  margin-bottom: 20px;
}

.user_info h6 {
  font-size: 16px;
}

.sidebar_menus ul .active,
.sidebar_menus ul a:hover {
  background-color: #e4f8ee;
}

.dash_header>div {
  min-height: auto !important;
  border-bottom: 1px solid #ededed;
  padding: 24px;
}

.dash_header svg {
  color: #fff;
}

.profile_page button,
.edit_fields button {
  background: transparent !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile_page button.Mui-selected,
.profile_page button:hover,
.edit_fields button.Mui-selected,
.edit_fields button:hover {
  color: #00793a;
}

.profile_page .MuiTabs-indicator,
.edit_fields .MuiTabs-indicator {
  background-color: #00793a;
}

.profile_page button svg,
.edit_fields button svg {
  margin: 0 5px 0 0 !important;
}

.otp_verify .right_side form {
  max-width: 480px;
  margin: auto;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 30px 20px;
}

.otp_verify h4 {
  margin-bottom: 30px;
}

.otp_verify div {
  justify-content: center;
}

.sign_up .right_side {
  padding: 40px 0;
}

.sign_up .location {
  padding: 16.5px 14px;
  height: auto !important;
  background: transparent !important;
}

.btn_icons button {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.btn_icons button svg {
  color: #fff;
  font-size: 16px;
}

.phone_field p {
  padding-left: 15px;
  color: inherit;
}

.dash_header button {
  background: #d7b56d;
}

.alumni_data {
  padding: 20px;
}

.search_area input {
  background: #fff;
}

.search_area>div {
  width: 100%;
  margin-bottom: 10px;
}

.title_page {
  display: flex;
  align-items: center;
  padding-top: 0 !important;
}

.alumni_data .rdt_TableHeadRow {
  background: #f9edd4;
}

.alumni_data .rdt_TableCol:first-child,
.alumni_data .rdt_TableRow>div:first-child {
  max-width: 100px;
}

.alumni_data .rdt_TableCol:last-child,
.alumni_data .rdt_TableRow>div:last-child {
  justify-content: center;
}

.calender_page .fc-button-group button {
  background-color: #f9edd4 !important;
  border: 1px solid #d7b56d !important;
  color: #000 !important;
}

.calender_page .fc-button-group button:focus {
  box-shadow: none !important;
}

.calender_page .fc-button-group .fc-button-active {
  background-color: #d7b56d !important;
  border: 1px solid #c7a459 !important;
  color: #fff !important;
}

.batch_page {
  margin-top: 28px;
}

.payment_table thead th {
  background-color: #f9edd4;
  color: #212B36;
}

.create_news,
.calendar_popup .MuiButton-containedPrimary {
  background-color: #00793a;
}

/* .sent_btn > div, .calendar_popup .MuiButton-containedError {
  background-color: #ca0000;
} */
.sent_btn>div:hover,
.calendar_popup .MuiButton-containedError:hover {
  background-color: #da2f2f;
}

.basic_info {
  padding: 0;
}

.about_alumni svg {
  background: #d7b56d;
  color: #fff;
  border-radius: 50%;
  padding: 8px;
  width: 35px;
  height: 35px;
}

.about_alumni>div {
  margin-top: 10px;
  align-items: center;
}

.tabs_rpadding,
.work_info,
.edu_info {
  padding: 0;
}

.calender_page .fc-button-primary {
  background-color: #00793a;
  border: 1px solid #05af57;
}

.create_news:hover,
.calendar_popup .MuiButton-containedPrimary:hover,
.calender_page .fc-button-primary:hover,
.save_btn button:hover {
  background-color: #d7b56d;
}

.edit_popup h6 {
  text-align: left;
  font-size: 16px;
}

.edit_popup {
  width: 100%;
}

.edit_data>div {
  display: block;
}

.edit_data div {
  margin-left: 0 !important;
  margin-bottom: 10px;
  padding-bottom: 0;
}

.sent_btn div,
.create_news {
  background-color: #00793a;
  color: #fff;
}




/***************Media*****************/
@media screen and (max-width: 1199px) {
  .dash_header>div {
    padding: 15px;
  }
}

@media screen and (max-width: 991px) {
  .sign_up .right_side {
    padding: 30px 15px !important;
  }

  .right_side h3 {
    margin: 25px 0;
  }

  .sign_in h4 {
    font-size: 22px;
  }

  .right_side {
    padding: 15px !important;
  }
}

@media screen and (max-width: 899px) {
  .sign_up img {
    display: none;
  }

  .student_img {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .edit_data>div {
    margin-bottom: 0;
  }
}

/* For screens smaller than 768px */
@media (max-width: 767px) {
  .margin_b {
    margin-bottom: 10px;
  }

  .padding_content {
    padding: 24px 0;
  }

  .edit_pp label {
    width: auto;
    margin: 20px auto 0;
    padding: 8px 20px;
  }

  .phone_field p {
    margin: 0 !important;
  }

  .otp_verify h4 {
    margin-bottom: 20px;
  }

  .otp_verify .right_side p {
    margin-bottom: 0;
  }

  .otp_verify .otp-input-container input {
    margin: 0 7px 0 0 !important;
  }

  .otp-input-container {
    display: flex;
    justify-content: center;
  }

  .otp-input-container input {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .right_side p {
    margin-bottom: 25px;
  }

  .logo_imgs img {
    max-width: 280px;
  }

  .logo_imgs a:last-child img {
    width: 200px;
  }
}


@media screen and (min-width: 601px) and (max-width: 767px) {
  .sign_in h4 {
    font-size: 20px;
  }

  .student_pp>div {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 600px) {
  .otp_verify .right_side form {
    padding: 30px 0 10px;
  }

  .logo_imgs a:last-child img {
    display: none;
  }

  .sign_in>div>div {
    flex-direction: column-reverse;
  }

  .right_side h3 {
    margin: 25px 0 15px;
  }

  .right_side {
    margin-left: 15px;
  }

  .logo_imgs {
    justify-content: center;
  }

  .logo_imgs img {
    max-width: 100%;
  }

  footer,
  footer a,
  .contact_info li p {
    font-size: 14px;
  }

  footer img {
    margin: auto;
  }

  footer h4 {
    margin-bottom: 10px !important;
    font-size: 18px !important;
  }

  .copyright p {
    text-align: center;
  }

  .sign_in img {
    display: none;
  }

  .student_pp>div {
    width: 80px;
    height: 80px;
    margin-top: 45px;
  }

  .User_name {
    font-size: 18px;
    padding-top: 18px;
  }
}

@media (max-width: 420px) {
  .sign_in h4 {
    font-size: 18px;
  }

  .right_side p {
    font-size: 14px;
  }

  .contact_info li svg {
    font-size: 15px;
    margin-right: 6px;
  }

  .contact_info li {
    padding-bottom: 15px;
  }

  .contact_info li:nth-child(2) a {
    font-size: 13px;
  }

  .basic_tabs {
    justify-content: start !important;
  }

  .basic_tabs div {
    display: block;
    width: 100%;
  }

  .basic_tabs button {
    width: 100%;
    justify-content: start;
    padding: 0 10px;
    min-height: auto;
    line-height: 50px;
  }

  .profile_page .MuiTabs-indicator,
  .edit_fields .MuiTabs-indicator {
    display: none;
  }

  .edit_tabs div,
  .edit_tabs {
    display: block;
  }

  .edit_tabs>div:first-child,
  .edit_tabs>div:last-child {
    display: none;
  }

  .edit_fields button {
    width: 100%;
    justify-content: start;
    line-height: 30px;
    min-height: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.ck-editor__editable_inline {
  min-height: 150px;
}

/* .timer {
  text-align: 'center';
  box-shadow: 0px 2px 4px #d7b56d;
  border-radius: 4px;
  width: 80px;
  height: 80px;
} */
@media screen and (min-width:320px) and (max-width: 400px) {

.calender_page {
  padding: 10px;
}
.fc .fc-toolbar-title {
  font-size: 16px;
  line-height: 36px;
}

.fc .fc-toolbar {
  display: block;
}

.calender_page {
  padding: 10px;
}


}
@media screen and (min-width:401px) and (max-width: 500px) {

  .calender_page {
    padding: 10px;
  }
  .fc .fc-toolbar-title {
    font-size: 14px;
  }
  
  
  
  }